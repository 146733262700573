<template>
  <div class="backdrop">
    <div class="b-modal">
        <div class="modal-header text-right">
              <h5 class="text-dark">Add Individual Associates</h5>
              <p @click="closeModal" class="x">x</p>
          </div>
          <div class="modalBody">
        
        <div v-if="step == '3'" class="mt-5">
             <p class="text-dark">Upload successful. You will be notified once it has been approved.</p>
             <p>
               <button class="btn btn-secondary submit" @click="done">Done</button>
               </p>
         </div>
         

      <form v-on:submit.prevent="uploadImage($event)" v-if="step == '1'">
          <label for="idType" class="text-dark">Document Type:</label>
              <p style="color: red;">{{ errors.profile.msg }}</p>  
             <div class="payment-container text-left d-block">
             <form class="d-block ">
                  <div class="form-check">
                 <input
                   class="form-check-input"
                   v-model="idType"
                   type="radio"
                   value="passport"
                   
                 />
                 <label class="form-check-label text-dark" for="passport">
                   Passport
                 </label>
               </div>

                <div class="form-check ">
                 <input
                   class="form-check-input"
                   v-model="idType"
                   type="radio"
                   value="nationalId"
                   
                 />
                 <label class="form-check-label text-dark" for="nationalId">
                   National ID
                 </label>
               </div>

                <div class="form-check ">
                 <input
                   class="form-check-input"
                   v-model="idType"
                   type="radio"
                   value="driversLicense"
                   
                 />
                 <label class="form-check-label text-dark" for="driversLicense">
                  Driver's License
                 </label>
               </div>
               <div class="form-check ">
                 <input
                   class="form-check-input"
                   v-model="idType"
                   type="radio"
                   value="sss"
                   
                 />
                 <label class="form-check-label text-dark" for="sss">
                  SSS
                 </label>
               </div>

               <div class="form-check ">
                 <input
                   class="form-check-input"
                   v-model="idType"
                   type="radio"
                   value="umid"
                   
                 />
                 <label class="form-check-label text-dark" for="umid">
                  UMID
                 </label>
               </div>
             

               
             </form>
             </div>
                 

       
         <div class="buttonWrapper" >
           
             <label v-if="idType == 'nationalId' || idType == 'driversLicense'" class="text-dark"> {{idType}} (Front)</label>
             <label v-else class="text-dark"> {{idType}} </label>
             <div class="dropbox text-dark">
               <input type="file" ref="fileInput" @change="handleFileSelect">
               <p class="text-dark">Selected file: {{ selectedFile }}</p>
             </div>
         </div>
         <div>
               <button type="submit" class="btn btn-secondary" id="saveBtn">Submit</button>
           </div>
         

         
     </form>
        <form v-on:submit.prevent="uploadImageBack($event)" v-if="step == '2'">
                 <div class="buttonWrapper">
                     <label class=" text-dark">{{idType}} (Back)</label>
                   <div class="dropbox text-dark" >
                       <input type="file" ref="fileInput" @change="handleFileSelectBack">
                       <p class="text-dark">Selected file: {{ selectedFileBack }}</p>
                     </div>

                 </div>
                 <div>
               <button type="submit" class="btn btn-secondary" id="saveBtn">Submit</button>
           </div>

             

        </form>

    
      
    
       <!--FAILED-->
       <div v-if="isFailed">
         <h2 class="red">Upload failed.</h2>
        
         <p  class="red">
           <a href="javascript:void(0)" @click="closeModal()">Try again</a>
         </p>
       </div>
       <div class="text-center">
         <img class="iconImg" >
      </div>
   </div>
    </div>
  </div>
</template>

<script>
import numeral from 'numeral'
import moment from 'moment'

export default {
    components: {

    },
    data: function() {
        return {
          uploadError:'',
            step:'1',
            idType:'',
            description:'VALID ID',
            selectedFile:'',
            selectedFileBack:'',
            idNumber:'111',
            uploadFieldName: 'imageFile',
            uploadFieldNameBack: 'imageFile',
            isSuccess: false,
            bothSuccess: false,
            isFailed: false,
            isLoading: false,
            response:'',
            errors: {
                profile: {
                    show:false,
                    msg: ""
                },
            },
           
            
        }
    },
    computed:{
        show() {
          return this.$store.state.show;
        },
        init() {
            return this.$store.state.init;
        },
       
     
      },
      methods: {
      uploadNow(){
        this.step = '1'
      },
          handleFileSelect() {
          const file = this.$refs.fileInput.files[0];
          this.selectedFile = file ? file.name : '';
          },
          handleFileSelectBack() {
          const file = this.$refs.fileInput.files[0];
          this.selectedFileBack = file ? file.name : '';
          },

        closeModal(){
            this.$emit("close")
            
        },

        done(){
          this.$emit("close");
          window.location.reload();
          
        },
         uploadImage(event) {
            this.isLoading = true
           if(this.idType == "" || this.idType == null || this.idType == undefined)  {
            this.errors.profile.show = true;
            this.errors.profile.msg = "This is required";
            this.isFailed = true
            setTimeout(()=>{
              this.errors.profile.msg = ""
              this.isFailed = false
            },2000)
            return false;
            }
            if(this.idNumber == "" || this.idNumber == null || this.idNumber == undefined)  {
            this.errors.profile.show = true;
            this.errors.profile.msg = "This is required";
            this.isFailed = true
            setTimeout(()=>{
              this.errors.profile.msg = ""
              this.isFailed = false
            },2000)
            return false;
            } 

            const params = {
              poidImageFile: this.selectedFile,
              poidType: this.idType,
              poidDescription: this.description,
              poidNumber: this.idNumber,
              eid: this.$route.params.entityId,
              hiddenGroup:'',
              redirectUrl:''

              }


         let data = new FormData();
                    data.append('poidImageFile', this.$refs.fileInput.files[0]);
                    data.append('poidType', this.idType);
                    data.append('poidDescription',this.description); 
                    data.append('poidNumber', this.idNumber);
                    data.append('eid', this.$route.params.entityId);
                    

            
            const options = {
                method: 'POST',
                headers: {
                      'content-type': 'image/png',
                      'vmiadmin-authz': localStorage.getItem("tokenId") 
                },
                data,
                url: '/ax/poidupload',
            };

           this.axios(options).then(
                response => {
                    console.log(response.request.status)
                    this.response = response.request.status
                    this.isLoading = false 
                    
                    if(this.response = 200){
                     
                        setTimeout( () => {
                            this.isLoading = false
                        
                           if(this.idType != 'passport'){
                              this.step = '2'
                           } 
                           if(this.idType === 'passport'){
                              this.step = '3'
                           }
                          
                            
                        },1000)
                    } else {
                        this.isFailed = true
                        setTimeout(()=>{
                        this.isFailed = false
                      },2000)
                    }
                }
              ).catch((err) => {
                  if(this.response = 500){
                      this.isFailed = true
                      setTimeout(()=>{
                        this.isFailed = false
                      },2000)
                  }

          })
        },
        uploadImageBack(event) {
          const params = {
              poidImageFile: this.selectedFile,
              poidType: this.idType + '-' + 'back',
              poidDescription: this.description,
              poidNumber: this.idNumber,
              eid: this.$route.params.entityId,
              hiddenGroup:'',
              redirectUrl:''

              }


         let data = new FormData();
                    data.append('poidImageFile', this.$refs.fileInput.files[0]);
                    data.append('poidType', this.idType + '-' + 'back');
                    data.append('poidDescription',this.description); 
                    data.append('poidNumber', this.idNumber);
                    data.append('eid', this.$route.params.entityId);
                    

            
            const options = {
                method: 'POST',
                headers: {
                      'content-type': 'image/png',
                      'vmiadmin-authz': localStorage.getItem("tokenId") 
                },
                data,
                url: '/ax/poidupload',
            };


           this.axios(options).then(
                response => {
                    console.log(response.request.status)
                    this.response = response.request.status
                    this.isLoading = true 
                    if(this.response = 200){
                        
                        setTimeout( () => {
                            this.isLoading = false
                            this.bothSuccess=true 
                            this.step = '3'

                        },3000)

                        
                    } else {
                        this.isFailed = true
                        setTimeout(()=>{
                        this.isFailed = false
                      },2000)
                    }
                    


                }
            ).catch((err) => {
                  if(this.response = 500){
                      this.isFailed = true
                      setTimeout(()=>{
                        this.isFailed = false
                      },2000)
                  }

          })
        },
      },
  
    mounted(){
        
        
    }
}
</script>

<style scoped lang=scss>
.backdrop {
    top: 0;
    right:0;
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 98;
    background: rgba(0, 0, 0, 0.6);

}
.b-modal {
    width: 700px;
    height: auto;
    margin: 20px auto;
    background: white;
    border-radius: 20px;
    border-color:white;
    border-width:2px;
    z-index: 99;
    max-height: calc(100vh - 110px);
      overflow-y: auto;

    color:black !important;
}

.modal-header {
    background-color: white;
    margin-right:0px;
    font-size:25px;
    display:flex;
    justify-content:space-between;
}

.modal-title{
    margin: 0 auto;
    color:white;
}
.market-content {
      width: 100%;
      border-radius: 10px;
      padding: 0;
      table {
        width: 100%;
        thead {
          th {
            font-size: 15px;
            color: #c5c2c2; 
            letter-spacing: 0.65px;
            padding: 1em;
            position: sticky;
            top: 0;
            background-image: linear-gradient(
            to bottom,
            #5a5f71 29%,
            #10121C 88%
      );
            text-align: inherit;
          }
        }
  
        tbody {
          background-image: linear-gradient(
            to bottom,
            #202538 29%,
            #10121C 88%
      );
          td {
            color: #FFFFFF;
            letter-spacing: 0.65px;
            padding: 1em;
  
            a {
              color: #FFFFFF;
              text-decoration: none;
  
              &:hover {
                text-decoration: underline;
              }  
            }
  
            &.hash {
              a {
                color: #FFBE21;
              }
              
            }
          }
        }
      }
    }
.modalBody {
    background-color:white;
    width:100%;
    padding:50px;
    border-radius: 10px;;
}

.buttonWrapper {
    width:100%;
}

.x {
    cursor:pointer;
}
.form-control{
  border-radius: 10px;;
}
.userPic{
    width: 10rem;
}
.image{
  width: 100%;
}
.img img{
    width: 100%;
    border-radius: 10px;;
     box-shadow: 0 2px 2px rgba(50,50,50,.50);
     border:7px solid #fff;
     border-radius: 10px;;
     background-color: white;
      background-size:cover;
      object-fit: contain;
  }

  .info{
    width: 100%;
    margin-left: 5rem;
    padding-left:1rem;
      .info .name{
      padding: 0.5rem 0 2rem 0;
        .info .name h3{
        font-size: 1rem;
        line-height:110%;
        font-weight: 400;
          .info .name p{
          font-size:0.9rem;
          color: black;
          }
        }
      }
    .info .desc .soc-med{
      display: flex !important;
      flex-direction: row;
      justify-content: space-evenly;
      font-size: 15px !important;
    }
  }
.profile-card {
  height: auto !important;
  width: 100%;
  margin: auto 10px;
  background: Orgba(255,255,255,0.2);
  box-shadow: 0px 8px 60px -10px rgba(13,28,39,0.6);
  border: 4px white solid;
  padding: 5px;
  border-radius: 12px;
  position: relative;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  transform-origin: bottom;
    &:hover{
    animation-name: bounce;
    animation-timing-function: ease;

    }

}
 

.iconImg {
    width:50%;
}
 .form-group{
        width: 100%;
    }

    .btn-main{
        margin: 30px auto;
        border: 1px solid #481a69;
        width: 100%;
    }
    .btn-main:hover{
        background-color:#481a69;
        color: white;
    }


    @media screen and (max-width: 1100px) {
     .body {
        height:auto;
        margin: 0 auto;
      }
     .heading {
         font-size:25px;
      }

      .subheading {
        font-size:12px;
      }
          
    }
   @media screen and (max-width: 800px) {
     .body {
        height:auto;
        margin: 0 auto;
      }
      .heading {
         font-size:25px;
      }

    .subheading {
      font-size:12px;
    }
    .card {
      width:15rem;
      height:21rem;
      font-size:10px;
    }
        
  }

    @media screen and (max-width: 600px) {
          .firstThree {
             margin: 0 auto;
             display: flex;
             justify-content: center;
        }
         .card {
            width:12rem;
            height:16rem;
            padding-bottom: 10px;
          }
          .card h6{
            font-size: 10px !important;
          }
        
    }

    @media screen and (max-width: 420px) {

       .b-modal {
            width: 400px;
            height: auto;
            margin-top: 50px;
            background: white;
            border-radius: 20px;
            border-color:white;
            border-width:2px;
            z-index: 1;
        }

        .modalBody {
            background-color:white;
            width:100%;
            padding: 20px 0px;
            border-radius: 10px;;
        }
        .submit {
            background-color:rgb(255, 102, 0);
            color:white;
            font-family:'PoppinsMedium', sans-serif;
            width:250px;
            margin-right:10px;
            margin-left:60px;
        }

        .cancel {
            color:#191919;
            background-color:lightgray;
            font-family:'PoppinsMedium', sans-serif;
            width:250px;
            margin-right:10px;
            margin-left:60px;
        }

        .textField {
            width:100px;
            padding:5px;
            border:none;
            color:#191919;
            background-color:lightgray;
            border-radius:3px;
            font-family: 'PoppinsRegular', sans-serif;
        }

        .checkboxGroup {
            width:300px;
            margin:0;
        }

        .sendCode {
            font-size:12px;
            margin-top: 5px;
            text-align:right;
            width:250px;
            font-weight:bold;
            cursor:pointer;
        }

        #pin1, #pin2, #pin3, #pin4, #pin5, #pin6 {
            width:40px;
            height:80px;
            padding:0;
            border:none;
            color:#191919;
            background-color:lightgray;
            border-radius:3px;
            font-family: 'PoppinsRegular', sans-serif;
            font-size:30px;
            margin:2px;
            display: flex !important;
        }

        #mobile1, #mobile2, #mobile3, #mobile4, #mobile5, #mobile6, #mobile7, #mobile8, #mobile9, #mobile10 {
            width:20px;
            padding:5px;
            border:none;
            color:#191919;
            background-color:lightgray;
            border-radius:3px;
            font-family: 'PoppinsRegular', sans-serif;
            margin:1px;
        }

        .otpLabel {
            font-size:15px;
            width:300px;
            padding:10px;
        }

        .checkWrapper {
            display: block;
            justify-content: flex-start;
            width:100%;
            margin-left:20px;
        }

        .buttonWrapper {
            display: block;
            justify-content: center;
            width:100%;
        }

    }

    @media screen and (max-width: 380px) {
        .b-modal {
                width: 315px;
        }

        #pin1, #pin2, #pin3, #pin4, #pin5, #pin6 {
            width:33px;
        }

        #areaCode h3{
            font-size:1px !important;
        }

        .mobileWrapper {
            display: flex;
            justify-content: flex-start;
            width:100%;
        }

        .formWrapper {
            display: flex;
            justify-content: flex-start;
            width:100%;
        }

        .checkWrapper {
            display: block;
            justify-content: flex-start;
            width:100%;
        }

        .buttonWrapper {
            display: block;
            justify-content: flex-start;
            width:100%;
            margin-left:0px;
        }

        .checkWrapper {
            display: block;
            justify-content: flex-start;
            width:100%;
            margin-left:0px;
        }

        .msgCon {
            width:300px;
        }

        .submit {
            background-color:rgb(255, 102, 0);
            color:white;
            font-family:'PoppinsMedium', sans-serif;
            width:250px;
            margin-right:10px;
            margin-left:20px;
        }

        .cancel {
            color:#191919;
            background-color:lightgray;
            font-family:'PoppinsMedium', sans-serif;
            width:250px;
            margin-right:10px;
            margin-left:20px;
        }
        
    }
    .loading-spinner {
      border: 4px solid rgba(0, 0, 0, 0.3);
      border-top: 4px solid #007BFF; /* Change the color to your preferred color */
      border-radius: 50%;
      width: 40px;
      height: 40px;
      animation: spin 1s linear infinite;
      margin: 0 auto;
    }
    @keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>